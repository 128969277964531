* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  display: none;
}

.home-Wrapper {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 75px;
  padding-left: 0px;
}
.home-Wrapper nav {
  z-index: 99;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.home-Wrapper nav .logo {
  width: 120px;
  height: 48px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 50px;
}
.home-Wrapper nav .logo img {
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.home-Wrapper nav .menu-list {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.home-Wrapper nav .menu-list li a {
  cursor: pointer;
  text-decoration: none;
  margin-top: 0px;
  margin-right: 30px;
  margin-bottom: 0px;
  margin-left: 0px;
  color: #000;
  font-size: 18px;
  word-spacing: 0.8px;
  line-height: 0px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.home-Wrapper nav .menu-list li:last-child {
  margin-top: 0px;
  margin-right: 30px;
  margin-bottom: 0px;
  margin-left: 0px;
}
.home-Wrapper .hero-section {
  margin-top: 10px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-direction: row;
}
.home-Wrapper .hero-section .home-textSection {
  width: 45%;
  margin-top: 30px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}
.downloads {
  display: flex;
  gap: 10px;
}
.downloads img {
  width: 200px;
  height: 60px;
}
@media screen and (max-width: 1000px) {
  .downloads img {
    width: 130px;
    height: 40px;
  }
}
@media screen and (max-width: 600px) {
  .downloads {
    align-items: center;
    justify-content: center;
  }
}
.home-Wrapper .hero-section .home-textSection h1 {
  color: #000;
  font-size: 48px;
  font-weight: 500;
  letter-spacing: 0px;
}
.home-Wrapper .hero-section .home-textSection p {
  margin-top: 10px;
  margin-right: 0px;
  margin-bottom: 10px;
  margin-left: 0px;
  color: rgba(0, 0, 0, 0.7019607843);
  font-size: 18px;
}
.home-Wrapper .hero-section .home-textSection .playstore-download {
  background-image: url("../images/google-play-png-logo.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 200px;
  height: 90px;
}
.home-Wrapper .hero-section .home-imageSection {
  width: 540px;
  height: 730px;
}
.home-Wrapper .hero-section .home-imageSection img {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1200px) {
  .home-Wrapper {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 50px;
    padding-left: 0px;
  }
  .home-Wrapper nav {
    padding-top: 20px;
    padding-right: 10px;
    padding-bottom: 20px;
    padding-left: 10px;
  }
  .home-Wrapper nav .logo {
    width: 115px;
    height: 45px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 30px;
  }
  .home-Wrapper nav .menu-list li a {
    margin-top: 0px;
    margin-right: 30px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  .home-Wrapper nav .menu-list li:last-child {
    margin-top: 0px;
    margin-right: 10px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  .home-Wrapper .hero-section .home-textSection {
    width: 50%;
    margin-top: 60px;
    margin-right: 20px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 20px;
  }
  .home-Wrapper .hero-section .home-textSection h1 {
    color: #000;
    font-size: 42px;
    word-spacing: 0.5px;
    line-height: 1.4em;
    font-weight: 500;
    letter-spacing: 0px;
  }
  .home-Wrapper .hero-section .home-textSection p {
    color: rgba(0, 0, 0, 0.7019607843);
    font-size: 22px;
    word-spacing: 0.1px;
    line-height: 1.2em;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  .home-Wrapper .hero-section .home-textSection .playstore-download {
    width: 180px;
    height: 80px;
  }
  .home-Wrapper .hero-section .home-imageSection {
    margin-top: 20px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    width: 460px;
    height: 600px;
  }
}
@media screen and (max-width: 1000px) {
  .home-Wrapper {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 30px;
    padding-left: 0px;
  }
  .home-Wrapper nav {
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
  }
  .home-Wrapper nav .logo {
    width: 112px;
    height: 45px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 20px;
  }
  .home-Wrapper nav .menu-list li a {
    margin-top: 0px;
    margin-right: 20px;
    margin-bottom: 0px;
    margin-left: 0px;
    color: #000;
    font-size: 17px;
    word-spacing: 0.8px;
    line-height: 0px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  .home-Wrapper nav .menu-list li:last-child {
    margin-top: 0px;
    margin-right: 5px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  .home-Wrapper .hero-section {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 0px;
    padding-right: 20px;
    padding-bottom: 0px;
    padding-left: 20px;
  }
  .home-Wrapper .hero-section .home-textSection {
    width: 48%;
    margin-top: 80px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  .home-Wrapper .hero-section .home-textSection h1 {
    color: #000;
    font-size: 34px;
    word-spacing: 0.8px;
    line-height: 1.4em;
    font-weight: 500;
    letter-spacing: 0px;
  }
  .home-Wrapper .hero-section .home-textSection p {
    margin-top: 15px;
    margin-right: 0px;
    margin-bottom: 15px;
    margin-left: 0px;
    color: rgba(0, 0, 0, 0.7019607843);
    font-size: 19px;
    word-spacing: 0.2px;
    line-height: 1.2em;
    font-weight: 500;
    letter-spacing: 0.4px;
  }
  .home-Wrapper .hero-section .home-textSection .playstore-download {
    width: 165px;
    height: 65px;
  }
  .home-Wrapper .hero-section .home-imageSection {
    width: 430px;
    height: 540px;
  }
}
@media screen and (max-width: 800px) {
  .home-Wrapper {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 30px;
    padding-left: 0px;
  }
  .home-Wrapper nav {
    padding-top: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
  }
  .home-Wrapper nav .logo {
    width: 90px;
    height: 36px;
  }
  .home-Wrapper nav .menu-list li a {
    margin-top: 0px;
    margin-right: 5px;
    margin-bottom: 0px;
    margin-left: 20px;
    color: #000000;
    font-size: 16px;
    word-spacing: 0.8px;
    line-height: 0px;
    font-weight: 500;
    letter-spacing: 0px;
  }
  .home-Wrapper .hero-section {
    margin-top: 15px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 10px;
  }
  .home-Wrapper .hero-section .home-textSection {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 10px;
    margin-top: 70px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  .home-Wrapper .hero-section .home-textSection h1 {
    color: #000;
    font-size: 30px;
    word-spacing: 0.8px;
    line-height: 1.4em;
    font-weight: 500;
    letter-spacing: 0.8px;
  }
  .home-Wrapper .hero-section .home-textSection p {
    margin-top: 15px;
    margin-right: 0px;
    margin-bottom: 15px;
    margin-left: 0px;
    color: rgba(0, 0, 0, 0.7019607843);
    font-size: 16px;
    word-spacing: 0.2px;
    line-height: 1em;
    font-weight: 500;
    letter-spacing: 0.3px;
  }
  .home-Wrapper .hero-section .home-textSection .playstore-download {
    width: 125px;
    height: 55px;
  }
  .home-Wrapper .hero-section .home-imageSection {
    width: 350px;
    height: 440px;
  }
}
@media screen and (max-width: 600px) {
  .home-Wrapper nav {
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-left: 5px;
  }
  .home-Wrapper nav .logo {
    width: 90px;
    height: 35px;
  }
  .home-Wrapper nav .menu-list li a {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 20px;
    color: #000;
    font-size: 14px;
    word-spacing: 0.8px;
    line-height: 0px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  .home-Wrapper .hero-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .home-Wrapper .hero-section .home-textSection {
    width: 100%;
    text-align: center;
    padding-top: 0px;
    padding-right: 30px;
    padding-bottom: 0px;
    padding-left: 30px;
    margin-top: 20px;
    margin-right: 0px;
    margin-bottom: 20px;
    margin-left: 0px;
  }
  .home-Wrapper .hero-section .home-textSection h1 {
    color: #000;
    font-size: 28px;
    word-spacing: 0.5px;
    line-height: 1.2em;
    font-weight: 500;
    letter-spacing: 0.8px;
  }
  .home-Wrapper .hero-section .home-textSection p {
    margin-top: 10px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
    color: rgba(0, 0, 0, 0.7019607843);
    font-size: 15px;
    word-spacing: 0.3px;
    line-height: 1.2em;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  .home-Wrapper .hero-section .home-textSection .playstore-download {
    margin: auto;
    width: 150px;
    height: 60px;
  }
  .home-Wrapper .hero-section .home-imageSection {
    width: 340px;
    height: 440px;
  }
}
@media screen and (max-width: 425px) {
  .home-Wrapper nav {
    padding-top: 15px;
    padding-right: 10px;
    padding-bottom: 15px;
    padding-left: 0px;
  }
  .home-Wrapper nav .logo {
    width: 80px;
    height: 35px;
  }
  .home-Wrapper nav .menu-list li a {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 20px;
    color: #000;
    font-size: 12px;
    word-spacing: 0.8px;
    line-height: 0px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  .home-Wrapper .hero-section {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }
  .home-Wrapper .hero-section .home-textSection {
    margin-top: 30px;
    margin-right: auto;
    margin-bottom: 15px;
    margin-left: auto;
    padding-top: 0px;
    padding-right: 15px;
    padding-bottom: 0px;
    padding-left: 15px;
  }
  .home-Wrapper .hero-section .home-textSection h1 {
    color: #000;
    font-size: 24px;
    word-spacing: 0.5px;
    line-height: 1.4em;
    font-weight: 500;
    letter-spacing: 0.8px;
  }
  .home-Wrapper .hero-section .home-textSection p {
    margin-top: 15px;
    margin-right: 0px;
    margin-bottom: 15px;
    margin-left: 0px;
    color: rgba(0, 0, 0, 0.7019607843);
    font-size: 14px;
    word-spacing: 0.3px;
    line-height: 1.3em;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  .home-Wrapper .hero-section .home-imageSection {
    width: 340px;
    height: 440px;
  }
}
@media screen and (max-width: 375px) {
  .home-Wrapper nav {
    padding-top: 10px;
    padding-right: 5px;
    padding-bottom: 10px;
    padding-left: 5px;
  }
  .home-Wrapper nav .logo {
    width: 80px;
    height: 35px;
    margin-top: 8px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  .home-Wrapper .hero-section .home-textSection {
    margin-top: 20px;
    margin-right: 0px;
    margin-bottom: 6px;
    margin-left: 0px;
  }
  .home-Wrapper .hero-section .home-textSection h1 {
    color: #000;
    font-size: 24px;
    word-spacing: 0.5px;
    line-height: 1.3em;
    font-weight: 500;
    letter-spacing: 0.8px;
  }
  .home-Wrapper .hero-section .home-textSection p {
    margin-top: 8px;
    margin-right: 0px;
    margin-bottom: 8px;
    margin-left: 0px;
    color: rgba(0, 0, 0, 0.7019607843);
    font-size: 13px;
    word-spacing: 0.3px;
    line-height: 1.3em;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  .home-Wrapper .hero-section .home-imageSection {
    width: 98%;
    height: 420px;
  }
}

.aboutUs-wrapper {
  margin-top: 50px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}
.aboutUs-wrapper h2 {
  text-align: center;
  color: #000;
  font-size: 36px;
  word-spacing: 0.5px;
  line-height: 1.9em;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.aboutUs-wrapper .about-section {
  margin-top: 30px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
}
.aboutUs-wrapper .about-section .about-imageSection {
  width: 480px;
  height: 550px;
  transition: all 0.5s ease-in-out;
}
.aboutUs-wrapper .about-section .about-imageSection:hover {
  transform: scale(1.1);
}
.aboutUs-wrapper .about-section .about-imageSection img {
  width: 100%;
  height: 100%;
}
.aboutUs-wrapper .about-section .about-paradivision {
  width: 40%;
}
.aboutUs-wrapper .about-section .about-paradivision p {
  margin-top: 20px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-top: 0px;
  padding-right: 30px;
  padding-bottom: 0px;
  padding-left: 60px;
  color: rgba(0, 0, 0, 0.7019607843);
  font-size: 24px;
}
@media screen and (max-width: 1200px) {
  .aboutUs-wrapper h2 {
    color: #000;
    font-size: 34px;
    word-spacing: 0.5px;
    line-height: 1.7em;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .aboutUs-wrapper .about-section .about-imageSection {
    width: 440px;
    height: 500px;
  }
  .aboutUs-wrapper .about-section .about-paradivision {
    width: 50%;
  }
  .aboutUs-wrapper .about-section .about-paradivision p {
    color: rgba(0, 0, 0, 0.7019607843);
    font-size: 22px;
    word-spacing: 0.8px;
    line-height: 1.5em;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
}
@media screen and (max-width: 1000px) {
  .aboutUs-wrapper h2 {
    color: #000;
    font-size: 32px;
    word-spacing: 0.5px;
    line-height: 1.6em;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .aboutUs-wrapper .about-section .about-imageSection {
    width: 420px;
    height: 460px;
  }
  .aboutUs-wrapper .about-section .about-paradivision {
    width: 45%;
  }
  .aboutUs-wrapper .about-section .about-paradivision p {
    color: rgba(0, 0, 0, 0.7019607843);
    font-size: 20px;
    word-spacing: 0.8px;
    line-height: 1.3em;
    font-weight: 500;
    letter-spacing: 0px;
  }
}
@media screen and (max-width: 800px) {
  .aboutUs-wrapper h2 {
    color: #000;
    font-size: 30px;
    word-spacing: 0.5px;
    line-height: 1.9em;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .aboutUs-wrapper .about-section .about-imageSection {
    width: 390px;
    height: 430px;
  }
  .aboutUs-wrapper .about-section .about-paradivision p {
    color: rgba(0, 0, 0, 0.7019607843);
    font-size: 18px;
    word-spacing: 0.8px;
    line-height: 1.4em;
    font-weight: 500;
    letter-spacing: 0.2px;
  }
}
@media screen and (max-width: 600px) {
  .aboutUs-wrapper h2 {
    color: #000;
    font-size: 28px;
    word-spacing: 0.5px;
    line-height: 1.9em;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .aboutUs-wrapper .about-section {
    margin-top: 30px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .aboutUs-wrapper .about-section .about-imageSection {
    width: 380px;
    height: 400px;
  }
  .aboutUs-wrapper .about-section .about-paradivision {
    width: 100%;
  }
  .aboutUs-wrapper .about-section .about-paradivision p {
    padding-top: 0px;
    padding-right: 35px;
    padding-bottom: 0px;
    padding-left: 35px;
    margin-top: 20px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    color: rgba(0, 0, 0, 0.7019607843);
    font-size: 16px;
    word-spacing: 1px;
    line-height: 1.7em;
    font-weight: 500;
    letter-spacing: 0.7px;
  }
}
@media screen and (max-width: 425px) {
  .aboutUs-wrapper {
    margin-top: 80px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  .aboutUs-wrapper h2 {
    color: #000;
    font-size: 26px;
    word-spacing: 0.5px;
    line-height: 1.9em;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .aboutUs-wrapper .about-section {
    margin-top: 20px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  .aboutUs-wrapper .about-section .about-imageSection {
    width: 90%;
    height: 380px;
  }
  .aboutUs-wrapper .about-section .about-paradivision p {
    margin-top: 15px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    color: rgba(0, 0, 0, 0.7019607843);
    font-size: 14px;
    word-spacing: 0.8px;
    line-height: 1.5em;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
}
@media screen and (max-width: 375px) {
  .aboutUs-wrapper {
    margin-top: 60px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  .aboutUs-wrapper h2 {
    color: #000;
    font-size: 24px;
    word-spacing: 0.5px;
    line-height: 1.2em;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .aboutUs-wrapper .about-section {
    padding-top: 0px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-left: 10px;
  }
  .aboutUs-wrapper .about-section .about-imageSection {
    width: 100%;
    height: 350px;
  }
  .aboutUs-wrapper .about-section .about-paradivision p {
    margin-top: 10px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    color: rgba(0, 0, 0, 0.7019607843);
    font-size: 14px;
    word-spacing: 0.8px;
    line-height: 1.5em;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
}

.servicesWrapper {
  margin-top: 50px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  overflow: hidden;
}
.servicesWrapper .servicesShortHead {
  width: 70%;
  text-align: center;
  margin-top: 0px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  padding-top: 0px;
  padding-right: 20px;
  padding-bottom: 0px;
  padding-left: 20px;
}
.servicesWrapper .servicesShortHead h2 {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 20px;
  margin-left: 0px;
  color: #000;
  font-size: 36px;
  word-spacing: 0.5px;
  line-height: 1.7em;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.servicesWrapper .servicesShortHead span {
  color: rgba(0, 0, 0, 0.7019607843);
  font-size: 20px;
  word-spacing: 0.8px;
  line-height: 1.5em;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.servicesWrapper .servicesContainer {
  margin-top: 60px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.servicesWrapper .servicesContainer .serviceContainer-text {
  width: 60%;
  padding-top: 0px;
  padding-right: 20px;
  padding-bottom: 0px;
  padding-left: 0px;
}
.servicesWrapper .servicesContainer .serviceContainer-text .servicesQuote {
  margin-top: 30px;
  margin-right: 0px;
  margin-bottom: 40px;
  margin-left: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
}
.servicesWrapper .servicesContainer .serviceContainer-text .servicesQuote img {
  width: 65px;
  height: 65px;
  margin-top: 0px;
  margin-right: 20px;
  margin-bottom: 0px;
  margin-left: 0px;
}
.servicesWrapper
  .servicesContainer
  .serviceContainer-text
  .servicesQuote
  .servicsInfo {
  width: 80%;
}
.servicesWrapper
  .servicesContainer
  .serviceContainer-text
  .servicesQuote
  .servicsInfo
  h3 {
  color: #000;
  font-size: 22px;
  word-spacing: 0.2px;
  line-height: 1.2em;
  font-weight: 600;
  letter-spacing: 0px;
}
.servicesWrapper
  .servicesContainer
  .serviceContainer-text
  .servicesQuote
  .servicsInfo
  p {
  margin-top: 5px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 18px;
  word-spacing: 0.2px;
  line-height: 1.2em;
  font-weight: 400;
  letter-spacing: 0px;
}
.servicesWrapper .servicesContainer .servicesImageSection {
  width: 380px;
  height: 620px;
  transition: all 0.5s ease-in;
}
.servicesWrapper .servicesContainer .servicesImageSection:hover {
  transform: rotate(-8deg);
}
.servicesWrapper .servicesContainer .servicesImageSection img {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1200px) {
  .servicesWrapper .servicesShortHead {
    width: 85%;
  }
  .servicesWrapper .servicesShortHead h2 {
    color: #000;
    font-size: 34px;
    word-spacing: 0.5px;
    line-height: 1.7em;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .servicesWrapper .servicesContainer {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 60px;
  }
  .servicesWrapper .servicesContainer .serviceContainer-text {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }
  .servicesWrapper .servicesContainer .servicesImageSection {
    width: 375px;
    height: 600px;
  }
}
@media screen and (max-width: 1000px) {
  .servicesWrapper .servicesShortHead {
    width: 90%;
  }
  .servicesWrapper .servicesShortHead h2 {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 15px;
    margin-left: 0px;
    color: #000;
    font-size: 32px;
    word-spacing: 0.5px;
    line-height: 1.7em;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .servicesWrapper .servicesShortHead span {
    color: rgba(0, 0, 0, 0.7019607843);
    font-size: 18px;
    word-spacing: 0.8px;
    line-height: 1.3em;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  .servicesWrapper .servicesContainer {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 50px;
  }
  .servicesWrapper .servicesContainer .serviceContainer-text {
    width: 55%;
  }
  .servicesWrapper
    .servicesContainer
    .serviceContainer-text
    .servicesQuote
    img {
    width: 60px;
    height: 60px;
    margin-top: 0px;
    margin-right: 10px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  .servicesWrapper
    .servicesContainer
    .serviceContainer-text
    .servicesQuote
    .servicsInfo
    h3 {
    color: #000;
    font-size: 20px;
    word-spacing: 0.2px;
    line-height: 1.2em;
    font-weight: 600;
    letter-spacing: 0px;
  }
  .servicesWrapper
    .servicesContainer
    .serviceContainer-text
    .servicesQuote
    .servicsInfo
    p {
    color: rgba(0, 0, 0, 0.4);
    font-size: 16px;
    word-spacing: 0.2px;
    line-height: 1.2em;
    font-weight: 400;
    letter-spacing: 0px;
  }
  .servicesWrapper .servicesContainer .servicesImageSection {
    width: 330px;
    height: 540px;
  }
}
@media screen and (max-width: 800px) {
  .servicesWrapper {
    margin-top: 150px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  .servicesWrapper .servicesShortHead h2 {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 15px;
    margin-left: 0px;
    color: #000;
    font-size: 30px;
    word-spacing: 0.5px;
    line-height: 1.7em;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .servicesWrapper .servicesShortHead span {
    color: rgba(0, 0, 0, 0.7019607843);
    font-size: 16px;
    word-spacing: 0.8px;
    line-height: 1.3em;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  .servicesWrapper .servicesContainer {
    margin-top: 30px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 30px;
  }
  .servicesWrapper .servicesContainer .serviceContainer-text {
    width: 50%;
  }
  .servicesWrapper
    .servicesContainer
    .serviceContainer-text
    .servicesQuote
    img {
    width: 55px;
    height: 55px;
  }
  .servicesWrapper
    .servicesContainer
    .serviceContainer-text
    .servicesQuote
    .servicsInfo {
    width: 100%;
  }
  .servicesWrapper
    .servicesContainer
    .serviceContainer-text
    .servicesQuote
    .servicsInfo
    h3 {
    color: #000;
    font-size: 18px;
    word-spacing: 0.2px;
    line-height: 1.2em;
    font-weight: 600;
    letter-spacing: 0px;
  }
  .servicesWrapper
    .servicesContainer
    .serviceContainer-text
    .servicesQuote
    .servicsInfo
    p {
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    word-spacing: 0.2px;
    line-height: 1.2em;
    font-weight: 400;
    letter-spacing: 0px;
  }
  .servicesWrapper .servicesContainer .servicesImageSection {
    width: 290px;
    height: 480px;
  }
}
@media screen and (max-width: 600px) {
  .servicesWrapper {
    margin-top: 120px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  .servicesWrapper .servicesShortHead h2 {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
    color: #000;
    font-size: 26px;
    word-spacing: 0.5px;
    line-height: 1.7em;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .servicesWrapper .servicesShortHead span {
    color: rgba(0, 0, 0, 0.7019607843);
    font-size: 14px;
    word-spacing: 0.8px;
    line-height: 1.3em;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  .servicesWrapper .servicesContainer {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .servicesWrapper .servicesContainer .serviceContainer-text {
    width: 80%;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 50px;
    margin-left: auto;
  }
  .servicesWrapper .servicesContainer .servicesImageSection {
    width: 330px;
    height: 540px;
  }
}
@media screen and (max-width: 425px) {
  .servicesWrapper {
    margin-top: 100px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  .servicesWrapper .servicesShortHead h2 {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
    color: #000;
    font-size: 24px;
    word-spacing: 0.5px;
    line-height: 1.7em;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .servicesWrapper .servicesShortHead span {
    color: rgba(0, 0, 0, 0.7019607843);
    font-size: 12px;
    word-spacing: 0.8px;
    line-height: 1.3em;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  .servicesWrapper .servicesContainer .serviceContainer-text {
    width: 100%;
    padding-top: 0px;
    padding-right: 15px;
    padding-bottom: 0px;
    padding-left: 15px;
  }
  .servicesWrapper .servicesContainer .serviceContainer-text .servicesQuote {
    width: 90%;
    margin-top: 50px;
    margin-right: auto;
    margin-bottom: 20px;
    margin-left: auto;
  }
  .servicesWrapper
    .servicesContainer
    .serviceContainer-text
    .servicesQuote
    img {
    width: 48px;
    height: 48px;
  }
  .servicesWrapper .servicesContainer .servicesImageSection {
    width: 300px;
    height: 490px;
  }
}
@media screen and (max-width: 375px) {
  .servicesWrapper .servicesShortHead {
    width: 100%;
  }
  .servicesWrapper .servicesShortHead h2 {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 5px;
    margin-left: 0px;
    color: #000;
    font-size: 22px;
    word-spacing: 0.5px;
    line-height: 1.7em;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .servicesWrapper .servicesContainer {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 10px;
    margin-top: 30px;
    margin-right: auto;
    margin-bottom: 5px;
    margin-left: auto;
  }
  .servicesWrapper .servicesContainer .serviceContainer-text .servicesQuote {
    width: 100%;
    margin-top: 25px;
    margin-right: auto;
    margin-bottom: 25px;
    margin-left: auto;
  }
  .servicesWrapper
    .servicesContainer
    .serviceContainer-text
    .servicesQuote
    .servicsInfo {
    width: 100%;
  }
  .servicesWrapper
    .servicesContainer
    .serviceContainer-text
    .servicesQuote
    .servicsInfo
    h3 {
    color: #000;
    font-size: 14px;
    word-spacing: 0.2px;
    line-height: 1.2em;
    font-weight: 600;
    letter-spacing: 0px;
  }
  .servicesWrapper
    .servicesContainer
    .serviceContainer-text
    .servicesQuote
    .servicsInfo
    p {
    color: rgba(0, 0, 0, 0.4);
    font-size: 12px;
    word-spacing: 0.2px;
    line-height: 1.2em;
    font-weight: 400;
    letter-spacing: 0px;
  }
  .servicesWrapper .servicesContainer .servicesImageSection {
    width: 250px;
    height: 400px;
  }
}

.demo-wrapper {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 50px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}
.demo-wrapper h2 {
  text-align: center;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
  margin-left: 0px;
  color: #000;
  font-size: 36px;
  word-spacing: 0.5px;
  line-height: 1.9em;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.demo-wrapper .demo-bodysection {
  position: relative;
  width: 100%;
  height: 90vh;
}
.demo-wrapper .demo-bodysection .demo-videoSection {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 246px;
  height: 506px;
  top: 50%;
  right: 0%;
  bottom: 0%;
  left: 50%;
}
.demo-wrapper .demo-bodysection .demo-videoSection video {
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 0px 0px 28px 28px;
  width: 100%;
  height: 100%;
}
.demo-wrapper .demo-bodysection .icon-wrapperhide {
  visibility: hidden;
}
.demo-wrapper .demo-bodysection .icon-wrappershow {
  visibility: visible;
}
.demo-wrapper .demo-bodysection .fa-play {
  z-index: 2;
  width: 30px;
  height: 30px;
  display: flex;
  font-size: 25px;
  cursor: pointer;
  position: absolute;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  top: 55%;
  right: 0%;
  bottom: 0%;
  left: 50%;
}
.demo-wrapper .demo-bodysection img {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 450px;
  height: 650px;
  top: 50%;
  right: 0%;
  bottom: 0%;
  left: 50%;
}
@media screen and (max-width: 1200px) {
  .demo-wrapper h2 {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 80px;
    margin-left: 0px;
    color: #000;
    font-size: 34px;
    word-spacing: 0.5px;
    line-height: 1.7em;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .demo-wrapper .demo-bodysection {
    width: 100%;
    height: 100vh;
  }
  .demo-wrapper .demo-bodysection .demo-videoSection {
    width: 242px;
    height: 480px;
  }
  .demo-wrapper .demo-bodysection .demo-videoSection video {
    border-radius: 0px 0px 28px 28px;
  }
  .demo-wrapper .demo-bodysection img {
    width: 443px;
    height: 614px;
  }
}
@media screen and (max-width: 1000px) {
  .demo-wrapper h2 {
    color: #000;
    font-size: 32px;
    word-spacing: 0.5px;
    line-height: 1.6em;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .demo-wrapper .demo-bodysection {
    width: 100%;
    height: 100vh;
  }
  .demo-wrapper .demo-bodysection .demo-videoSection {
    width: 240px;
    height: 517px;
  }
  .demo-wrapper .demo-bodysection .demo-videoSection video {
    border-radius: 0px 0px 28px 28px;
  }
  .demo-wrapper .demo-bodysection .fa-play {
    font-size: 20px;
  }
  .demo-wrapper .demo-bodysection img {
    width: 440px;
    height: 650px;
  }
}
@media screen and (max-width: 800px) {
  .demo-wrapper h2 {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 30px;
    margin-left: 0px;
    color: #000;
    font-size: 30px;
    word-spacing: 0.5px;
    line-height: 1.9em;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .demo-wrapper .demo-bodysection {
    width: 100%;
    height: 90vh;
  }
  .demo-wrapper .demo-bodysection .demo-videoSection {
    width: 192px;
    height: 388px;
  }
  .demo-wrapper .demo-bodysection .demo-videoSection video {
    border-radius: 0px 0px 20px 20px;
  }
  .demo-wrapper .demo-bodysection .icon-wrapper {
    width: 50px;
    height: 50px;
  }
  .demo-wrapper .demo-bodysection .icon-wrapper .clip_play_Icon {
    width: 30px;
    height: 30px;
  }
  .demo-wrapper .demo-bodysection img {
    width: 350px;
    height: 500px;
  }
}
@media screen and (max-width: 600px) {
  .demo-wrapper h2 {
    color: #000;
    font-size: 28px;
    word-spacing: 0.5px;
    line-height: 1.9em;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .demo-wrapper .demo-bodysection {
    width: 100%;
    height: 80vh;
  }
  .demo-wrapper .demo-bodysection .demo-videoSection {
    width: 190px;
    height: 374px;
  }
  .demo-wrapper .demo-bodysection .demo-videoSection video {
    border-radius: 0px 0px 20px 20px;
  }
  .demo-wrapper .demo-bodysection img {
    width: 340px;
    height: 480px;
  }
}
@media screen and (max-width: 425px) {
  .demo-wrapper h2 {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 50px;
    margin-left: 0px;
    color: #000;
    font-size: 26px;
    word-spacing: 0.5px;
    line-height: 1.9em;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .demo-wrapper .demo-bodysection .demo-videoSection {
    width: 175px;
    height: 355px;
  }
  .demo-wrapper .demo-bodysection .demo-videoSection video {
    border-radius: 0px 0px 20px 20px;
  }
  .demo-wrapper .demo-bodysection img {
    width: 322px;
    height: 454px;
  }
}
@media screen and (max-width: 375px) {
  .demo-wrapper h2 {
    color: #000;
    font-size: 24px;
    word-spacing: 0.5px;
    line-height: 1.2em;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .demo-wrapper .demo-bodysection .demo-videoSection {
    width: 176px;
    height: 374px;
  }
  .demo-wrapper .demo-bodysection img {
    width: 322px;
    height: 474px;
  }
}

.footer-wrapper {
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 70px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-top: 100px;
  padding-right: 0px;
  padding-bottom: 10px;
  padding-left: 0px;
  background-image: url("../images/footer_bg_desk.png");
}
.footer-wrapper .contact-section .form-container {
  text-align: center;
}
.footer-wrapper .contact-section .form-container h2 {
  margin-top: 30px;
  margin-right: auto;
  margin-bottom: 30px;
  margin-left: auto;
  color: #fff;
  font-size: 24px;
  word-spacing: 0.8px;
  line-height: 1.5em;
  font-weight: 0;
  letter-spacing: 0.5px;
}
.footer-wrapper .contact-section .form-container form {
  width: 450px;
  height: 400px;
  margin-top: 0px;
  margin-right: auto;
  margin-bottom: 10px;
  margin-left: auto;
}
.footer-wrapper .contact-section .form-container form .form_control {
  margin-top: 8px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
}
.footer-wrapper .contact-section .form-container form .form_control input {
  border: none;
  border-radius: 5px;
  background: rgba(250, 250, 250, 0.5019607843);
  width: 100%;
  height: 38px;
  padding-top: 0px;
  padding-right: 10px;
  padding-bottom: 0px;
  padding-left: 10px;
  color: #fff;
  font-size: 15px;
  word-spacing: 0.8px;
  line-height: 1.5em;
  font-weight: 500;
  letter-spacing: 1.5px;
}
.footer-wrapper
  .contact-section
  .form-container
  form
  .form_control
  input::-moz-placeholder {
  color: #fff;
  font-size: 15px;
  word-spacing: 0.8px;
  line-height: 1.5em;
  font-weight: 500;
  letter-spacing: 1px;
}
.footer-wrapper
  .contact-section
  .form-container
  form
  .form_control
  input:-ms-input-placeholder {
  color: #fff;
  font-size: 15px;
  word-spacing: 0.8px;
  line-height: 1.5em;
  font-weight: 500;
  letter-spacing: 1px;
}
.footer-wrapper
  .contact-section
  .form-container
  form
  .form_control
  input::placeholder {
  color: #fff;
  font-size: 15px;
  word-spacing: 0.8px;
  line-height: 1.5em;
  font-weight: 500;
  letter-spacing: 1px;
}
.footer-wrapper
  .contact-section
  .form-container
  form
  .form_control
  input:focus {
  outline: none;
}
.footer-wrapper .contact-section .form-container form .form_control textarea {
  width: 100%;
  border: none;
  border-radius: 5px;
  background: rgba(250, 250, 250, 0.5019607843);
  margin-top: 0px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 0px;
  padding-left: 10px;
  color: #fff;
  font-size: 15px;
  word-spacing: 0.8px;
  line-height: 1.5em;
  font-weight: 500;
  letter-spacing: 1.5px;
}
.footer-wrapper
  .contact-section
  .form-container
  form
  .form_control
  textarea::-moz-placeholder {
  color: #fff;
  font-size: 15px;
  word-spacing: 0.8px;
  line-height: 1.5em;
  font-weight: 500;
  letter-spacing: 1px;
}
.footer-wrapper
  .contact-section
  .form-container
  form
  .form_control
  textarea:-ms-input-placeholder {
  color: #fff;
  font-size: 15px;
  word-spacing: 0.8px;
  line-height: 1.5em;
  font-weight: 500;
  letter-spacing: 1px;
}
.footer-wrapper
  .contact-section
  .form-container
  form
  .form_control
  textarea::placeholder {
  color: #fff;
  font-size: 15px;
  word-spacing: 0.8px;
  line-height: 1.5em;
  font-weight: 500;
  letter-spacing: 1px;
}
.footer-wrapper
  .contact-section
  .form-container
  form
  .form_control
  textarea:focus {
  outline: none;
}
.footer-wrapper .contact-section .form-container form .form_control .errors {
  text-align: start;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 5px;
  color: #ea4335;
  font-size: 12px;
  word-spacing: 0.2px;
  line-height: 1.5em;
  font-weight: 500;
  letter-spacing: 1.5px;
}
.footer-wrapper .contact-section .form-container form .contactBtn {
  border: none;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 15px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-top: 12px;
  padding-right: 64px;
  padding-bottom: 12px;
  padding-left: 64px;
  color: #33004b;
  font-size: 14px;
  word-spacing: 0.2px;
  line-height: 1.5em;
  font-weight: 600;
  letter-spacing: 1px;
}
.footer-wrapper .social-section {
  padding-top: 0px;
  padding-right: 20px;
  padding-bottom: 0px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}
@media screen and (max-width: 800px) {
  .footer-wrapper .social-section {
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
}
.footer-wrapper .social-section .social-icon {
  text-align: center;
}
.footer-wrapper .social-section .social-icon h3 {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  color: #fff;
  font-size: 16px;
  word-spacing: 0.8px;
  line-height: 1.5em;
  font-weight: 600;
  letter-spacing: 1px;
}
.footer-wrapper .social-section .social-icon img {
  width: 38px;
  height: 38px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 2px;
  transition: all 0.3s ease-in-out;
}
.footer-wrapper .social-section .social-icon img:hover {
  transform: rotate(20deg);
}
.footer-wrapper .social-section .social-icon p {
  margin-top: 10px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  color: #fff;
  font-size: 12px;
  word-spacing: 0.8px;
  line-height: 1.2em;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.social-copyright p {
  margin-right: 0px;
  margin-left: 0px;
  color: #fff;
  font-size: 12px;
  word-spacing: 0.8px;
  line-height: 1.2em;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.footer-wrapper .social-section .social-privacySection {
  display: flex;
  justify-content: space-between;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 5px;
}
.footer-wrapper .social-section .social-privacySection h6 a {
  text-decoration: none;
  color: #fff;
  font-size: 12px;
  word-spacing: 0.8px;
  line-height: 1.2em;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.footer-wrapper .social-section .social-privacySection h6 {
  color: #fff;
  font-size: 12px;
}
@media screen and (max-width: 800px) {
  .footer-wrapper {
    margin-top: 150px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  .footer-wrapper .contact-section .form-container h2 {
    color: #fff;
    font-size: 22px;
    word-spacing: 0.8px;
    line-height: 1.5em;
    font-weight: 0;
    letter-spacing: 0.5px;
  }
  .footer-wrapper .contact-section .form-container form {
    width: 400px;
    height: 380px;
  }
  .footer-wrapper .contact-section .form-container form .form_control input {
    color: #fff;
    font-size: 14px;
    word-spacing: 0.8px;
    line-height: 1.5em;
    font-weight: 500;
    letter-spacing: 1.5px;
  }
  .footer-wrapper
    .contact-section
    .form-container
    form
    .form_control
    input::-moz-placeholder {
    color: #fff;
    font-size: 14px;
    word-spacing: 0.8px;
    line-height: 1.5em;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .footer-wrapper
    .contact-section
    .form-container
    form
    .form_control
    input:-ms-input-placeholder {
    color: #fff;
    font-size: 14px;
    word-spacing: 0.8px;
    line-height: 1.5em;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .footer-wrapper
    .contact-section
    .form-container
    form
    .form_control
    input::placeholder {
    color: #fff;
    font-size: 14px;
    word-spacing: 0.8px;
    line-height: 1.5em;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .footer-wrapper .contact-section .form-container form .form_control textarea {
    color: #fff;
    font-size: 14px;
    word-spacing: 0.8px;
    line-height: 1.5em;
    font-weight: 500;
    letter-spacing: 1.5px;
  }
  .footer-wrapper
    .contact-section
    .form-container
    form
    .form_control
    textarea::-moz-placeholder {
    color: #fff;
    font-size: 14px;
    word-spacing: 0.8px;
    line-height: 1.5em;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .footer-wrapper
    .contact-section
    .form-container
    form
    .form_control
    textarea:-ms-input-placeholder {
    color: #fff;
    font-size: 14px;
    word-spacing: 0.8px;
    line-height: 1.5em;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .footer-wrapper
    .contact-section
    .form-container
    form
    .form_control
    textarea::placeholder {
    color: #fff;
    font-size: 14px;
    word-spacing: 0.8px;
    line-height: 1.5em;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .footer-wrapper .contact-section .form-container form .form_control .errors {
    color: #ea4335;
    font-size: 12px;
    word-spacing: 0.2px;
    line-height: 1.5em;
    font-weight: 500;
    letter-spacing: 1.5px;
  }
  .footer-wrapper .contact-section .form-container form .contactBtn {
    padding-top: 10px;
    padding-right: 52px;
    padding-bottom: 10px;
    padding-left: 52px;
    color: #33004b;
    font-size: 13px;
    word-spacing: 0.2px;
    line-height: 1.5em;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .footer-wrapper .social-section {
    padding-top: 0px;
    padding-right: 15px;
    padding-bottom: 0px;
    padding-left: 15px;
  }
  .footer-wrapper .social-section .social-icon h3 {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 8px;
    margin-left: 0px;
    color: #fff;
    font-size: 14px;
    word-spacing: 0.8px;
    line-height: 1.5em;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .footer-wrapper .social-section .social-icon img {
    width: 32px;
    height: 32px;
  }
}
@media screen and (max-width: 600px) {
  .footer-wrapper {
    background-position: center;
    background-size: cover;
    background-image: url("../images/footer_bg.png");
  }
  .footer-wrapper .contact-section .form-container h2 {
    color: #fff;
    font-size: 20px;
    word-spacing: 0.8px;
    line-height: 1.5em;
    font-weight: 0;
    letter-spacing: 0.5px;
  }
  .footer-wrapper .contact-section .form-container form {
    width: 80%;
    height: 380px;
  }
  .footer-wrapper .social-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .footer-wrapper .social-copyrightSection {
    margin: auto;
  }
  .footer-wrapper .social-copyrightSection p {
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    color: #fff;
    font-size: 10px;
    word-spacing: 0.8px;
    line-height: 1.2em;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
}
@media screen and (max-width: 425px) {
  .footer-wrapper {
    padding-top: 200px;
    padding-right: 0px;
    padding-bottom: 10px;
    padding-left: 0px;
  }
  .footer-wrapper .contact-section .form-container h2 {
    color: #fff;
    font-size: 18px;
    word-spacing: 0.8px;
    line-height: 1.5em;
    font-weight: 0;
    letter-spacing: 0.5px;
  }
  .footer-wrapper .contact-section .form-container form {
    width: 90%;
    height: 380px;
  }
}

.privacy-section nav {
  z-index: 99;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.privacy-section nav .logo {
  width: 120px;
  height: 48px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 50px;
}
.privacy-section nav .logo img {
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.privacy-section nav .menu-list {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.privacy-section nav .menu-list li a {
  cursor: pointer;
  text-decoration: none;
  margin-top: 0px;
  margin-right: 30px;
  margin-bottom: 0px;
  margin-left: 0px;
  color: #000;
  font-size: 18px;
  word-spacing: 0.8px;
  line-height: 0px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.privacy-section nav .menu-list li:last-child {
  margin-top: 0px;
  margin-right: 30px;
  margin-bottom: 0px;
  margin-left: 0px;
}
.privacy-section .privacy-container {
  padding: 0px 10px;
  width: 90%;
  margin: auto;
}
.privacy-section .privacy-container .privacyBox,
.privacy-section .privacy-container .shortIntroPrivacy,
.privacy-section .privacy-container .cookieSection,
.privacy-section .privacy-container .dataSection,
.privacy-section .privacy-container .datasecondbox,
.privacy-section .privacy-container .partnerSection,
.privacy-section .privacy-container .serviceSection,
.privacy-section .privacy-container .storeIntroSection,
.privacy-section .privacy-container .comGroupSection,
.privacy-section .privacy-container .bottomLine {
  margin: 20px auto 0px;
}
.privacy-section .privacy-container .privacyBox h3,
.privacy-section .privacy-container .shortIntroPrivacy h3,
.privacy-section .privacy-container .cookieSection h3,
.privacy-section .privacy-container .dataSection h3,
.privacy-section .privacy-container .datasecondbox h3,
.privacy-section .privacy-container .partnerSection h3,
.privacy-section .privacy-container .serviceSection h3,
.privacy-section .privacy-container .storeIntroSection h3,
.privacy-section .privacy-container .comGroupSection h3,
.privacy-section .privacy-container .bottomLine h3 {
  color: #000;
  font-size: 20px;
  word-spacing: 1px;
  line-height: 1.5em;
  font-weight: 700;
  letter-spacing: 0.9px;
}
.privacy-section .privacy-container .privacyBox p,
.privacy-section .privacy-container .shortIntroPrivacy p,
.privacy-section .privacy-container .cookieSection p,
.privacy-section .privacy-container .dataSection p,
.privacy-section .privacy-container .datasecondbox p,
.privacy-section .privacy-container .partnerSection p,
.privacy-section .privacy-container .serviceSection p,
.privacy-section .privacy-container .storeIntroSection p,
.privacy-section .privacy-container .comGroupSection p,
.privacy-section .privacy-container .bottomLine p {
  margin-top: 5px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  color: #000;
  font-size: 14px;
  word-spacing: 2px;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.privacy-section .privacy-container .articleSection h2 {
  margin-top: 80px;
  margin-right: 0px;
  margin-bottom: 40px;
  margin-left: 0px;
  color: #000;
  font-size: 18px;
  word-spacing: 1px;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0.9px;
}
.privacy-section .privacy-container .articleSection .listmenu-wrapper p {
  color: #000;
  font-size: 14px;
  word-spacing: 2px;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.privacy-section
  .privacy-container
  .articleSection
  .listmenu-wrapper
  .bulletpoint {
  padding: 0px 30px;
}
.privacy-section
  .privacy-container
  .articleSection
  .listmenu-wrapper
  .bulletpoint
  ul {
  margin-top: 15px;
  margin-right: 0px;
  margin-bottom: 30px;
  margin-left: 0px;
}
.privacy-section
  .privacy-container
  .articleSection
  .listmenu-wrapper
  .bulletpoint
  ul
  li {
  margin-top: 15px;
  margin-right: 0px;
  margin-bottom: 15px;
  margin-left: 0px;
  color: #000;
  font-size: 14px;
  word-spacing: 2px;
  line-height: 1.8em;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.privacy-section .privacy-container .shortIntroPrivacy h3,
.privacy-section .privacy-container .cookieSection h3,
.privacy-section .privacy-container .dataSection h3,
.privacy-section .privacy-container .datasecondbox h3,
.privacy-section .privacy-container .partnerSection h3,
.privacy-section .privacy-container .serviceSection h3,
.privacy-section .privacy-container .storeIntroSection h3,
.privacy-section .privacy-container .comGroupSection h3,
.privacy-section .privacy-container .bottomLine h3 {
  color: #000;
  font-size: 16px;
  word-spacing: 2px;
  line-height: 1em;
  font-weight: 700;
  letter-spacing: 0.5px;
}
@media screen and (max-width: 1200px) {
  .privacy-section nav {
    padding-top: 20px;
    padding-right: 10px;
    padding-bottom: 20px;
    padding-left: 10px;
  }
  .privacy-section nav .logo {
    width: 115px;
    height: 45px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 30px;
  }
  .privacy-section nav .menu-list li a {
    margin-top: 0px;
    margin-right: 30px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  .privacy-section nav .menu-list li:last-child {
    margin-top: 0px;
    margin-right: 10px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
}
@media screen and (max-width: 1000px) {
  .privacy-section nav {
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
  }
  .privacy-section nav .logo {
    width: 112px;
    height: 45px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 20px;
  }
  .privacy-section nav .menu-list li a {
    margin-top: 0px;
    margin-right: 20px;
    margin-bottom: 0px;
    margin-left: 0px;
    color: #000;
    font-size: 17px;
    word-spacing: 0.8px;
    line-height: 0px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  .privacy-section nav .menu-list li:last-child {
    margin-top: 0px;
    margin-right: 5px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
}
@media screen and (max-width: 800px) {
  .privacy-section nav {
    padding-top: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
  }
  .privacy-section nav .logo {
    width: 90px;
    height: 36px;
  }
  .privacy-section nav .menu-list li a {
    margin-top: 0px;
    margin-right: 5px;
    margin-bottom: 0px;
    margin-left: 20px;
    color: #000000;
    font-size: 16px;
    word-spacing: 0.8px;
    line-height: 0px;
    font-weight: 500;
    letter-spacing: 0px;
  }
}
@media screen and (max-width: 600px) {
  .privacy-section nav {
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-left: 5px;
  }
  .privacy-section nav .logo {
    width: 90px;
    height: 35px;
  }
  .privacy-section nav .menu-list li a {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 20px;
    color: #000;
    font-size: 14px;
    word-spacing: 0.8px;
    line-height: 0px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  .privacy-section .privacy-container {
    padding: 0px;
    width: 90%;
  }
  .privacy-section .privacy-container .privacyBox,
  .privacy-section .privacy-container .shortIntroPrivacy,
  .privacy-section .privacy-container .cookieSection,
  .privacy-section .privacy-container .dataSection,
  .privacy-section .privacy-container .datasecondbox,
  .privacy-section .privacy-container .partnerSection,
  .privacy-section .privacy-container .serviceSection,
  .privacy-section .privacy-container .storeIntroSection,
  .privacy-section .privacy-container .comGroupSection,
  .privacy-section .privacy-container .bottomLine {
    margin: 30px auto 0px;
  }
  .privacy-section .privacy-container .privacyBox h3,
  .privacy-section .privacy-container .shortIntroPrivacy h3,
  .privacy-section .privacy-container .cookieSection h3,
  .privacy-section .privacy-container .dataSection h3,
  .privacy-section .privacy-container .datasecondbox h3,
  .privacy-section .privacy-container .partnerSection h3,
  .privacy-section .privacy-container .serviceSection h3,
  .privacy-section .privacy-container .storeIntroSection h3,
  .privacy-section .privacy-container .comGroupSection h3,
  .privacy-section .privacy-container .bottomLine h3 {
    color: #000;
    font-size: 16px;
    word-spacing: 1px;
    line-height: 1.5em;
    font-weight: 700;
    letter-spacing: 0.9px;
  }
  .privacy-section .privacy-container .privacyBox p,
  .privacy-section .privacy-container .shortIntroPrivacy p,
  .privacy-section .privacy-container .cookieSection p,
  .privacy-section .privacy-container .dataSection p,
  .privacy-section .privacy-container .datasecondbox p,
  .privacy-section .privacy-container .partnerSection p,
  .privacy-section .privacy-container .serviceSection p,
  .privacy-section .privacy-container .storeIntroSection p,
  .privacy-section .privacy-container .comGroupSection p,
  .privacy-section .privacy-container .bottomLine p {
    margin-top: 5px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    color: #000;
    font-size: 12px;
    word-spacing: 2px;
    line-height: 1.5em;
    font-weight: 400;
    letter-spacing: 0.5px;
  }
  .privacy-section .privacy-container .articleSection h2 {
    margin-top: 40px;
    margin-right: 0px;
    margin-bottom: 20px;
    margin-left: 0px;
    color: #000;
    font-size: 15px;
    word-spacing: 1px;
    line-height: 1.5em;
    font-weight: 400;
    letter-spacing: 0.9px;
  }
  .privacy-section .privacy-container .articleSection .listmenu-wrapper p {
    color: #000;
    font-size: 12px;
    word-spacing: 2px;
    line-height: 1.5em;
    font-weight: 400;
    letter-spacing: 0.5px;
  }
  .privacy-section
    .privacy-container
    .articleSection
    .listmenu-wrapper
    .bulletpoint {
    padding: 0px 10px 0px 20px;
  }
  .privacy-section
    .privacy-container
    .articleSection
    .listmenu-wrapper
    .bulletpoint
    ul {
    margin-top: 20px;
    margin-right: 0px;
    margin-bottom: 20px;
    margin-left: 0px;
  }
  .privacy-section
    .privacy-container
    .articleSection
    .listmenu-wrapper
    .bulletpoint
    ul
    li {
    margin-top: 20px;
    margin-right: 0px;
    margin-bottom: 20px;
    margin-left: 0px;
    color: #000;
    font-size: 12px;
    word-spacing: 2px;
    line-height: 1.8em;
    font-weight: 400;
    letter-spacing: 0.5px;
  }
  .privacy-section .privacy-container .shortIntroPrivacy h3,
  .privacy-section .privacy-container .cookieSection h3,
  .privacy-section .privacy-container .dataSection h3,
  .privacy-section .privacy-container .datasecondbox h3,
  .privacy-section .privacy-container .partnerSection h3,
  .privacy-section .privacy-container .serviceSection h3,
  .privacy-section .privacy-container .storeIntroSection h3,
  .privacy-section .privacy-container .comGroupSection h3,
  .privacy-section .privacy-container .bottomLine h3 {
    color: #000;
    font-size: 14px;
    word-spacing: 2px;
    line-height: 1em;
    font-weight: 700;
    letter-spacing: 0.5px;
  }
}
@media screen and (max-width: 425px) {
  .privacy-section nav {
    padding-top: 15px;
    padding-right: 10px;
    padding-bottom: 15px;
    padding-left: 0px;
  }
  .privacy-section nav .logo {
    width: 80px;
    height: 35px;
  }
  .privacy-section nav .menu-list li a {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 20px;
    color: #000;
    font-size: 12px;
    word-spacing: 0.8px;
    line-height: 0px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
}

.termconditionsection nav {
  z-index: 99;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.termconditionsection nav .logo {
  width: 120px;
  height: 48px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 50px;
}
.termconditionsection nav .logo img {
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.termconditionsection nav .menu-list {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.termconditionsection nav .menu-list li a {
  cursor: pointer;
  text-decoration: none;
  margin-top: 0px;
  margin-right: 30px;
  margin-bottom: 0px;
  margin-left: 0px;
  color: #000;
  font-size: 18px;
  word-spacing: 0.8px;
  line-height: 0px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.termconditionsection nav .menu-list li:last-child {
  margin-top: 0px;
  margin-right: 30px;
  margin-bottom: 0px;
  margin-left: 0px;
}
.termconditionsection .condition-container {
  padding: 0px 10px;
  width: 90%;
  margin: auto;
}
.termconditionsection .condition-container .conditionBox,
.termconditionsection .condition-container .accessAppBox,
.termconditionsection .condition-container .eligiblityBox,
.termconditionsection .condition-container .userAccountBox,
.termconditionsection .condition-container .paymentIntrBox {
  margin: 20px auto 0px;
}
.termconditionsection .condition-container .conditionBox h3,
.termconditionsection .condition-container .accessAppBox h3,
.termconditionsection .condition-container .eligiblityBox h3,
.termconditionsection .condition-container .userAccountBox h3,
.termconditionsection .condition-container .paymentIntrBox h3 {
  color: #000;
  font-size: 16px;
  word-spacing: 0.5px;
  line-height: 1.2em;
  font-weight: 700;
  letter-spacing: 0.5px;
}
.termconditionsection .condition-container .conditionBox p,
.termconditionsection .condition-container .accessAppBox p,
.termconditionsection .condition-container .eligiblityBox p,
.termconditionsection .condition-container .userAccountBox p,
.termconditionsection .condition-container .paymentIntrBox p {
  margin-top: 5px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  color: #000;
  font-size: 14px;
  word-spacing: 1.5px;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0.5px;
}
@media screen and (max-width: 1200px) {
  .termconditionsection nav {
    padding-top: 20px;
    padding-right: 10px;
    padding-bottom: 20px;
    padding-left: 10px;
  }
  .termconditionsection nav .logo {
    width: 115px;
    height: 45px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 30px;
  }
  .termconditionsection nav .menu-list li a {
    margin-top: 0px;
    margin-right: 30px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  .termconditionsection nav .menu-list li:last-child {
    margin-top: 0px;
    margin-right: 10px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
}
@media screen and (max-width: 1000px) {
  .termconditionsection nav {
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
  }
  .termconditionsection nav .logo {
    width: 112px;
    height: 45px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 20px;
  }
  .termconditionsection nav .menu-list li a {
    margin-top: 0px;
    margin-right: 20px;
    margin-bottom: 0px;
    margin-left: 0px;
    color: #000;
    font-size: 17px;
    word-spacing: 0.8px;
    line-height: 0px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  .termconditionsection nav .menu-list li:last-child {
    margin-top: 0px;
    margin-right: 5px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
}
@media screen and (max-width: 800px) {
  .termconditionsection nav {
    padding-top: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
  }
  .termconditionsection nav .logo {
    width: 90px;
    height: 36px;
  }
  .termconditionsection nav .menu-list li a {
    margin-top: 0px;
    margin-right: 5px;
    margin-bottom: 0px;
    margin-left: 20px;
    color: #000000;
    font-size: 16px;
    word-spacing: 0.8px;
    line-height: 0px;
    font-weight: 500;
    letter-spacing: 0px;
  }
}
@media screen and (max-width: 600px) {
  .termconditionsection nav {
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-left: 5px;
  }
  .termconditionsection nav .logo {
    width: 90px;
    height: 35px;
  }
  .termconditionsection nav .menu-list li a {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 20px;
    color: #000;
    font-size: 14px;
    word-spacing: 0.8px;
    line-height: 0px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
  .termconditionsection .condition-container {
    padding: 0px 10px;
    width: 90%;
    margin: auto;
  }
  .termconditionsection .condition-container .conditionBox,
  .termconditionsection .condition-container .accessAppBox,
  .termconditionsection .condition-container .eligiblityBox,
  .termconditionsection .condition-container .userAccountBox,
  .termconditionsection .condition-container .paymentIntrBox {
    margin: 20px auto;
  }
  .termconditionsection .condition-container .conditionBox h3,
  .termconditionsection .condition-container .accessAppBox h3,
  .termconditionsection .condition-container .eligiblityBox h3,
  .termconditionsection .condition-container .userAccountBox h3,
  .termconditionsection .condition-container .paymentIntrBox h3 {
    color: #000;
    font-size: 14px;
    word-spacing: 0.5px;
    line-height: 1.2em;
    font-weight: 700;
    letter-spacing: 0.5px;
  }
  .termconditionsection .condition-container .conditionBox p,
  .termconditionsection .condition-container .accessAppBox p,
  .termconditionsection .condition-container .eligiblityBox p,
  .termconditionsection .condition-container .userAccountBox p,
  .termconditionsection .condition-container .paymentIntrBox p {
    margin-top: 5px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    color: #000;
    font-size: 12px;
    word-spacing: 1.5px;
    line-height: 1.5em;
    font-weight: 400;
    letter-spacing: 0.5px;
  }
}
@media screen and (max-width: 425px) {
  .termconditionsection nav {
    padding-top: 15px;
    padding-right: 10px;
    padding-bottom: 15px;
    padding-left: 0px;
  }
  .termconditionsection nav .logo {
    width: 80px;
    height: 35px;
  }
  .termconditionsection nav .menu-list li a {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 20px;
    color: #000;
    font-size: 12px;
    word-spacing: 0.8px;
    line-height: 0px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
} /*# sourceMappingURL=landingpageMain.css.map */

.prose {
  padding: 0px 20px;
  max-width: 1000px;
  margin: auto;
  text-align: justify;
}

.prose h1 {
  font-size: 25px;
  margin-bottom: 10px;
}

.prose h2 {
  font-size: 22px;
  margin-bottom: 8px;
  margin-top: 30px;
}

.prose h3 {
  font-size: 18px;
  margin-bottom: 8px;
  margin-top: 30px;
}

.prose p {
  margin-bottom: 10px;
}

.prose li {
  margin-left: 30px;
}

.prose table,
th,
td {
  border-collapse: collapse;
  border: 1px solid black;
  padding: 2px 10px;
}

.prose table {
  margin-bottom: 20px;
  margin-top: 10px;
}
