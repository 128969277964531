@import "../sass/landingpageVariables";
// Here Import LandigPage Variable File
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  display: none;
}

@import "../sass/landingpageHome";

@import "../sass/landingpageAbout";

@import "../sass/landingpageService";

@import "../sass/landingpageDemo";

@import "../sass/landingpageContact";

@import "../sass/landingpage_PrivacyComponent";

@import "../sass/landingpageTermCondition";
