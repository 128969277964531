/* @import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Proza+Libre:wght@100;200;300;400;500;600;700;800;900&display=swap");

html,
body {
  /* font-family: "Nunito", sans-serif; */
  font-family: "Proza Libre", sans-serif;
}

* {
  font-family: "Proza Libre", sans-serif;
  -webkit-tap-highlight-color: transparent;
}
