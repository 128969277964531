.demo-wrapper {
  @include width-height(100%, fit-content);
  @include text-margin(150px, 0px, 0px, 0px);

  h2 {
    text-align: center;
    @include text-margin(0px, 0px, 10px, 0px);
    @include text-style(#000, 36px, 0.5px, 1.9em, 600, 0.5px, sans-serif);
  }
  .demo-bodysection {
    position: relative;
    @include width-height(100%, 90vh);

    .demo-videoSection {
      position: absolute;
      transform: translate(-50%, -50%);
      @include width-height(246px, 506px);
      @include positionDirection(50%, 0%, 0%, 50%);

      video {
        object-fit: cover;
        border-radius: 0px 0px 28px 28px;
        @include width-height(100%, 100%);
      }
    }
    .icon-wrapperhide {
      visibility: hidden;
    }
    .icon-wrappershow {
      visibility: visible;
    }
    .fa-play {
      z-index: 2;
      width: 30px;
      height: 30px;
      display: flex;
      font-size: 25px;
      cursor: pointer;
      position: absolute;
      align-items: center;
      justify-content: center;
      transform: translate(-50%, -50%);
      @include positionDirection(55%, 0%, 0%, 50%);
    }
    img {
      position: absolute;
      transform: translate(-50%, -50%);
      @include width-height(450px, 650px);
      @include positionDirection(50%, 0%, 0%, 50%);
    }
  }

  @media screen and (max-width: 1200px) {
    h2 {
      @include text-margin(0px, 0px, 80px, 0px);
      @include text-style(#000, 34px, 0.5px, 1.7em, 600, 0.5px, sans-serif);
    }
    .demo-bodysection {
      @include width-height(100%, 100vh);

      .demo-videoSection {
        @include width-height(242px, 480px);

        video {
          border-radius: 0px 0px 28px 28px;
        }
      }
      img {
        @include width-height(443px, 614px);
      }
    }
  }
  // Here apply 1200px Media Queries
  @media screen and (max-width: 1000px) {
    h2 {
      @include text-style(#000, 32px, 0.5px, 1.6em, 600, 0.5px, sans-serif);
    }
    .demo-bodysection {
      @include width-height(100%, 100vh);

      .demo-videoSection {
        @include width-height(240px, 517px);

        video {
          border-radius: 0px 0px 28px 28px;
        }
      }
      .fa-play {
        font-size: 20px;
      }
      img {
        @include width-height(440px, 650px);
      }
    }
  }
  // Here apply 1000px Media Queries

  @media screen and (max-width: 800px) {
    h2 {
      @include text-margin(0px, 0px, 30px, 0px);
      @include text-style(#000, 30px, 0.5px, 1.9em, 600, 0.5px, sans-serif);
    }
    .demo-bodysection {
      @include width-height(100%, 90vh);

      .demo-videoSection {
        @include width-height(192px, 388px);

        video {
          border-radius: 0px 0px 20px 20px;
        }
      }
      .icon-wrapper {
        @include width-height(50px, 50px);

        .clip_play_Icon {
          width: 30px;
          height: 30px;
        }
      }
      img {
        @include width-height(350px, 500px);
      }
    }
  }
  // Here apply 800px Media Queries
  @media screen and (max-width: 600px) {
    h2 {
      @include text-style(#000, 28px, 0.5px, 1.9em, 600, 0.5px, sans-serif);
    }
    .demo-bodysection {
      @include width-height(100%, 80vh);

      .demo-videoSection {
        @include width-height(190px, 374px);
        video {
          border-radius: 0px 0px 20px 20px;
        }
      }
      img {
        @include width-height(340px, 480px);
      }
    }
  }
  // Here apply 600px Media Queries
  @media screen and (max-width: 425px) {
    h2 {
      @include text-margin(0px, 0px, 50px, 0px);
      @include text-style(#000, 26px, 0.5px, 1.9em, 600, 0.5px, sans-serif);
    }
    .demo-bodysection {
      .demo-videoSection {
        @include width-height(175px, 355px);
        video {
          border-radius: 0px 0px 20px 20px;
        }
      }
      img {
        @include width-height(322px, 454px);
      }
    }
  }
  // Here apply 425px Media Queries
  @media screen and (max-width: 375px) {
    h2 {
      @include text-style(#000, 24px, 0.5px, 1.2em, 600, 0.5px, sans-serif);
    }
    .demo-bodysection {
      .demo-videoSection {
        @include width-height(176px, 374px);
      }
      img {
        @include width-height(322px, 474px);
      }
    }
  }
  // Here apply 375px Media Queries
}
