/* CountryDropdown.css */
.country-dropdown {
  position: relative;
  display: inline-block;
}

.selected-country {
  cursor: pointer;
  border: 1px solid hsl(var(--border));
  padding: 10px;
  border-radius: 5px;
  display: flex;
  gap: 5px;
}

.country-list {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  overflow-y: auto;
  max-height: 300px;
  border: 1px solid #ccc;
  border-top: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: hsl(var(--background));
  border-radius: 10px;
  padding: 5px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.show {
  display: block;
}

.country-option {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
}
.country-option:hover {
  background-color: hsl(var(--border));
}

.country-option img {
  width: 30px;
  height: 20px;
  border-radius: 2px;
}

/* .selected-country.active {
  border-bottom: 1px solid red;
} */

.selected-country .arrow {
  margin-right: -2px;
}

.country-list.show {
  display: flex;
}

.flag-img {
  height: 20px;
  width: 30px;
  border-radius: 2px;
}
