@mixin homeSection-bg($image, $position, $size, $repet) {
  background-image: $image;
  background-position: $position;
  background-size: $size;
  background-repeat: $repet;
}
// Background Here

@mixin parent-flex($display, $content, $item, $wrap, $direction) {
  display: $display;
  justify-content: $content;
  align-items: $item;
  flex-wrap: $wrap;
  flex-direction: $direction;
}
// Flex Property

@mixin text-style(
  $color,
  $t-size,
  $w-space,
  $l-height,
  $f-weight,
  $l-space,
  $f-family
) {
  color: $color;
  font-size: $t-size;
  word-spacing: $w-space;
  line-height: $l-height;
  font-weight: $f-weight;
  letter-spacing: $l-space;
}
// Text Style Here

@mixin text-margin($top, $right, $bottom, $left) {
  margin-top: $top;
  margin-right: $right;
  margin-bottom: $bottom;
  margin-left: $left;
}
// Margin

@mixin text-padding($top, $right, $bottom, $left) {
  padding-top: $top;
  padding-right: $right;
  padding-bottom: $bottom;
  padding-left: $left;
}
// Padding

@mixin width-height($width, $height) {
  width: $width;
  height: $height;
}
//  Width & Height

@mixin positionDirection($top, $right, $bottom, $left) {
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
