.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  background-color: #f5f5f5;
  color: #000000;
}

.review {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 30px;
  width: 600px;
}

.title {
  font-weight: bold;
  font-size: 15px;
  text-align: center;
}

.rating-box {
  display: flex;
  gap: 10px;
}

.rating-btn {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border: 1px solid #b8b8b8;
  transition: all 0.5s ease;
  cursor: pointer;
}

.rating-btn.active {
  background-color: #000000;
  color: #f5f5f5;
}

.btn {
  height: 40px;
  width: 120px;
  border: none;
  background-color: #000000;
  color: #f5f5f5;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn:disabled {
  background-color: #00000070;
  cursor: not-allowed;
}
