.termconditionsection {
  nav {
    z-index: 99;
    @include text-padding(20px, 20px, 20px, 20px);
    @include parent-flex(flex, space-between, center, wrap, row);

    .logo {
      @include width-height(120px, 48px);
      @include text-margin(0px, 0px, 0px, 50px);

      img {
        cursor: pointer;
        @include width-height(100%, 100%);
      }
    } // Logo

    .menu-list {
      list-style: none;
      @include parent-flex(flex, space-between, center, wrap, row);

      li a {
        cursor: pointer;
        text-decoration: none;
        @include text-margin(0px, 30px, 0px, 0px);
        @include text-style(#000, 18px, 0.8px, 0px, 500, 0.5px, Arial);
      }
      li:last-child {
        @include text-margin(0px, 30px, 0px, 0px);
      }
    } // Menu-List
  } // Navbar
  .condition-container {
    padding: 0px 10px;
    width: 90%;
    margin: auto;
    .conditionBox,
    .accessAppBox,
    .eligiblityBox,
    .userAccountBox,
    .paymentIntrBox {
      margin: 20px auto 0px;
      h3 {
        @include text-style(#000, 16px, 0.5px, 1.2em, 700, 0.5px, Arial);
      }
      p {
        @include text-margin(5px, 0px, 0px, 0px);
        @include text-style(#000, 14px, 1.5px, 1.5em, 400, 0.5px, Arial);
      }
    }
  }

  @media screen and (max-width: 1200px) {
    nav {
      @include text-padding(20px, 10px, 20px, 10px);

      .logo {
        @include width-height(115px, 45px);
        @include text-margin(0px, 0px, 0px, 30px);
      }

      .menu-list {
        li a {
          @include text-margin(0px, 30px, 0px, 0px);
        }
        li:last-child {
          @include text-margin(0px, 10px, 0px, 0px);
        }
      }
    }
  }
  @media screen and (max-width: 1000px) {
    nav {
      @include text-padding(15px, 15px, 15px, 15px);

      .logo {
        @include width-height(112px, 45px);
        @include text-margin(0px, 0px, 0px, 20px);
      }

      .menu-list {
        li a {
          @include text-margin(0px, 20px, 0px, 0px);
          @include text-style(#000, 17px, 0.8px, 0px, 500, 0.5px, Arial);
        }
        li:last-child {
          @include text-margin(0px, 5px, 0px, 0px);
        }
      }
    }
  }
  @media screen and (max-width: 800px) {
    nav {
      @include text-padding(12px, 12px, 12px, 12px);

      .logo {
        @include width-height(90px, 36px);
      }

      .menu-list {
        li a {
          @include text-margin(0px, 5px, 0px, 20px);
          @include text-style(#000000, 16px, 0.8px, 0px, 500, 0px, Arial);
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    nav {
      @include text-padding(15px, 15px, 15px, 5px);

      .logo {
        @include width-height(90px, 35px);
      }

      .menu-list {
        li a {
          @include text-margin(0px, 0px, 0px, 20px);
          @include text-style(#000, 14px, 0.8px, 0px, 500, 0.5px, Arial);
        }
      }
    }
    .condition-container {
      padding: 0px 10px;
      width: 90%;
      margin: auto;
      .conditionBox,
      .accessAppBox,
      .eligiblityBox,
      .userAccountBox,
      .paymentIntrBox {
        margin: 20px auto;
        h3 {
          @include text-style(#000, 14px, 0.5px, 1.2em, 700, 0.5px, Arial);
        }
        p {
          @include text-margin(5px, 0px, 0px, 0px);
          @include text-style(#000, 12px, 1.5px, 1.5em, 400, 0.5px, Arial);
        }
      }
    }
  }
  @media screen and (max-width: 425px) {
    nav {
      @include text-padding(15px, 10px, 15px, 0px);

      .logo {
        @include width-height(80px, 35px);
      }

      .menu-list {
        li a {
          @include text-margin(0px, 0px, 0px, 20px);
          @include text-style(#000, 12px, 0.8px, 0px, 500, 0.5px, Arial);
        }
      }
    }
  }
}
