.footer-wrapper {
  background-size: cover;
  background-repeat: no-repeat;
  @include text-margin(180px, 0px, 0px, 0px);
  @include text-padding(100px, 0px, 10px, 0px);
  background-image: url("../images/footer_bg_desk.png");

  .contact-section {
    .form-container {
      text-align: center;

      h2 {
        @include text-margin(30px, auto, 30px, auto);
        @include text-style(#fff, 24px, 0.8px, 1.5em, 000, 0.5px, Arial);
      }
      form {
        @include width-height(450px, 400px);
        @include text-margin(0px, auto, 10px, auto);

        .form_control {
          @include text-margin(8px, auto, 0px, auto);

          input {
            border: none;
            border-radius: 5px;
            background: #fafafa80;
            @include width-height(100%, 38px);
            @include text-padding(0px, 10px, 0px, 10px);
            @include text-style(#fff, 15px, 0.8px, 1.5em, 500, 1.5px, Arial);

            &::placeholder {
              @include text-style(#fff, 15px, 0.8px, 1.5em, 500, 1px, Arial);
            }

            &:focus {
              outline: none;
            }
          }

          textarea {
            width: 100%;
            border: none;
            border-radius: 5px;
            background: #fafafa80;
            @include text-margin(0px, auto, 0px, auto);
            @include text-padding(10px, 10px, 0px, 10px);
            @include text-style(#fff, 15px, 0.8px, 1.5em, 500, 1.5px, Arial);

            &::placeholder {
              @include text-style(#fff, 15px, 0.8px, 1.5em, 500, 1px, Arial);
            }

            &:focus {
              outline: none;
            }
          }

          .errors {
            text-align: start;
            @include text-margin(0px, 0px, 0px, 5px);
            @include text-style(#ea4335, 12px, 0.2px, 1.5em, 500, 1.5px);
          }
        }
        .contactBtn {
          border: none;
          border-radius: 5px;
          background-color: #fff;
          @include text-margin(15px, 0px, 0px, 0px);
          @include text-padding(12px, 64px, 12px, 64px);
          @include text-style(#33004b, 14px, 0.2px, 1.5em, 600, 1px, Arial);
        }
      }
    }
  }

  .social-section {
    @include text-padding(0px, 20px, 0px, 20px);
    @include parent-flex(flex, space-between, flex-end, wrap, row);

    .social-icon {
      text-align: center;
      h3 {
        @include text-margin(0px, 0px, 0px, 0px);
        @include text-style(#fff, 16px, 0.8px, 1.5em, 600, 1px, Arial);
      }
      img {
        @include width-height(38px, 38px);
        @include text-margin(0px, 0px, 0px, 2px);
        transition: all 0.3s ease-in-out;

        &:hover {
          transform: rotate(20deg);
        }
      }
      p {
        @include text-margin(10px, 0px, 0px, 0px);
        @include text-style(#fff, 12px, 0.8px, 1.2em, 500, 0.5px, Arial);
      }
    }
    .social-copyright {
      p {
        @include text-margin(10px, 0px, 0px, 0px);
        @include text-style(#fff, 12px, 0.8px, 1.2em, 500, 0.5px, Arial);
      }
    }
    .social-privacySection {
      display: flex;
      justify-content: space-between;
      @include text-margin(20px, 0px, 0px, 0px);
      @include parent-flex(flex, space-between, flex-end, wrap, row);

      h6 a {
        text-decoration: none;
        @include text-style(#fff, 12px, 0.8px, 1.2em, 500, 0.5px, Arial);
      }
    }
  }
  @media screen and (max-width: 800px) {
    @include text-margin(150px, 0px, 0px, 0px);

    .contact-section {
      .form-container {
        h2 {
          @include text-style(#fff, 22px, 0.8px, 1.5em, 000, 0.5px, Arial);
        }
        form {
          @include width-height(400px, 380px);

          .form_control {
            input {
              @include text-style(#fff, 14px, 0.8px, 1.5em, 500, 1.5px, Arial);
              &::placeholder {
                @include text-style(#fff, 14px, 0.8px, 1.5em, 500, 1px, Arial);
              }
            }
            textarea {
              @include text-style(#fff, 14px, 0.8px, 1.5em, 500, 1.5px, Arial);

              &::placeholder {
                @include text-style(#fff, 14px, 0.8px, 1.5em, 500, 1px, Arial);
              }
            }
            .errors {
              @include text-style(
                #ea4335,
                12px,
                0.2px,
                1.5em,
                500,
                1.5px,
                Verdana
              );
            }
          }
          .contactBtn {
            @include text-padding(10px, 52px, 10px, 52px);
            @include text-style(#33004b, 13px, 0.2px, 1.5em, 600, 1px, Arial);
          }
        }
      }
    }
    .social-section {
      @include text-padding(0px, 15px, 0px, 15px);

      .social-icon {
        h3 {
          @include text-margin(0px, 0px, 8px, 0px);
          @include text-style(#fff, 14px, 0.8px, 1.5em, 600, 1px, Arial);
        }
        img {
          @include width-height(32px, 32px);
        }
      }
    }
  }
  // Here apply 800px Media Queries
  @media screen and (max-width: 600px) {
    background-position: center;
    background-size: cover;
    background-image: url("../images/footer_bg.png");

    .contact-section {
      .form-container {
        h2 {
          @include text-style(#fff, 20px, 0.8px, 1.5em, 000, 0.5px, Arial);
        }
        form {
          @include width-height(80%, 380px);
        }
      }
    }
    .social-section {
      @include parent-flex(flex, center, center, wrap, column);
    }
    .social-copyrightSection {
      margin: auto;
      p {
        @include text-margin(8px, 0px, 0px, 0px);
        @include text-style(#fff, 10px, 0.8px, 1.2em, 500, 0.5px, Arial);
      }
    }
  }
  // Here apply 600px Media Queries
  @media screen and (max-width: 425px) {
    @include text-padding(200px, 0px, 10px, 0px);

    .contact-section {
      .form-container {
        h2 {
          @include text-style(#fff, 18px, 0.8px, 1.5em, 000, 0.5px, Arial);
        }
        form {
          @include width-height(90%, 380px);
        }
      }
    }
  }
  // Here apply 425px Media Queries
}
