.card {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 7px;
  border-radius: 10px;
  border: 1px solid #eee;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05),
    0px 4px 4px 0px rgba(0, 0, 0, 0.04), 1px 9px 6px 0px rgba(0, 0, 0, 0.03),
    1px 16px 7px 0px rgba(0, 0, 0, 0.01), 2px 25px 7px 0px rgba(0, 0, 0, 0);
  width: 100%;
  text-decoration: none;
  color: black;
  transition: all 0.3s ease;
}

.card:hover {
  transform: scale(1.03);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1),
    0px 8px 8px 0px rgba(0, 0, 0, 0.1), 1px 10px 8px 0px rgba(0, 0, 0, 0.05),
    2px 26px 10px 0px rgba(0, 0, 0, 0.02), 2px 25px 7px 0px rgba(0, 0, 0, 0);
  border: 2px solid #eee;
}

.card-image {
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

.card-title {
  font-weight: 500;
  font-size: 16px;
}

.stars {
  display: flex;
  gap: 2px;
}
