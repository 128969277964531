.servicesWrapper {
  @include text-margin(200px, 0px, 0px, 0px);
  overflow-x: hidden;

  .servicesShortHead {
    width: 70%;
    text-align: center;
    @include text-margin(0px, auto, 0px, auto);
    @include text-padding(0px, 20px, 0px, 20px);

    h2 {
      @include text-margin(0px, 0px, 20px, 0px);
      @include text-style(#000, 36px, 0.5px, 1.7em, 600, 0.5px, sans-serif);
    }
    span {
      @include text-style(#000000b3, 20px, 0.8px, 1.5em, 500, 0.5px, Arial);
    }
  }
  .servicesContainer {
    @include text-margin(60px, 0px, 0px, 0px);
    @include text-padding(0px, 0px, 0px, 200px);
    @include parent-flex(flex, space-between, center, wrap, row);

    .serviceContainer-text {
      width: 60%;
      @include text-padding(0px, 20px, 0px, 0px);

      .servicesQuote {
        @include text-margin(30px, 0px, 40px, 0px);
        @include parent-flex(flex, flex-start, center, nowrap, row);

        img {
          @include width-height(65px, 65px);
          @include text-margin(0px, 20px, 0px, 0px);
        }
        .servicsInfo {
          width: 80%;

          h3 {
            @include text-style(#000, 22px, 0.2px, 1.2em, 600, 0px, Arial);
          }
          p {
            @include text-margin(5px, 0px, 0px, 0px);
            @include text-style(#00000066, 18px, 0.2px, 1.2em, 400, 0px, Arial);
          }
        }
      }
    }

    .servicesImageSection {
      @include width-height(380px, 620px);
      transition: all 0.5s ease-in;
      &:hover {
        transform: rotate(-8deg);
      }

      img {
        @include width-height(100%, 100%);
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .servicesShortHead {
      width: 85%;

      h2 {
        @include text-style(#000, 34px, 0.5px, 1.7em, 600, 0.5px, sans-serif);
      }
    }
    .servicesContainer {
      @include text-padding(0px, 0px, 0px, 60px);

      .serviceContainer-text {
        @include text-padding(0px, 0px, 0px, 0px);
      }

      .servicesImageSection {
        @include width-height(375px, 600px);
      }
    }
  }
  // Here apply 1200px Media Queries

  @media screen and (max-width: 1000px) {
    .servicesShortHead {
      width: 90%;

      h2 {
        @include text-margin(0px, 0px, 15px, 0px);

        @include text-style(#000, 32px, 0.5px, 1.7em, 600, 0.5px, sans-serif);
      }
      span {
        @include text-style(#000000b3, 18px, 0.8px, 1.3em, 500, 0.5px, Arial);
      }
    }
    .servicesContainer {
      @include text-padding(0px, 0px, 0px, 50px);

      .serviceContainer-text {
        width: 55%;

        .servicesQuote {
          img {
            @include width-height(60px, 60px);
            @include text-margin(0px, 10px, 0px, 0px);
          }
          .servicsInfo {
            h3 {
              @include text-style(#000, 20px, 0.2px, 1.2em, 600, 0px, Arial);
            }
            p {
              @include text-style(
                #00000066,
                16px,
                0.2px,
                1.2em,
                400,
                0px,
                Arial
              );
            }
          }
        }
      }
      .servicesImageSection {
        @include width-height(330px, 540px);
      }
    }
  }
  // Here apply 1000px Media Queries

  @media screen and (max-width: 800px) {
    @include text-margin(150px, 0px, 0px, 0px);

    .servicesShortHead {
      h2 {
        @include text-margin(0px, 0px, 15px, 0px);
        @include text-style(#000, 30px, 0.5px, 1.7em, 600, 0.5px, sans-serif);
      }
      span {
        @include text-style(#000000b3, 16px, 0.8px, 1.3em, 500, 0.5px, Arial);
      }
    }
    .servicesContainer {
      @include text-margin(30px, 0px, 0px, 0px);
      @include text-padding(0px, 0px, 0px, 30px);

      .serviceContainer-text {
        width: 50%;

        .servicesQuote {
          img {
            @include width-height(55px, 55px);
          }
          .servicsInfo {
            width: 100%;
            h3 {
              @include text-style(#000, 18px, 0.2px, 1.2em, 600, 0px, Arial);
            }
            p {
              @include text-style(
                #00000066,
                14px,
                0.2px,
                1.2em,
                400,
                0px,
                Arial
              );
            }
          }
        }
      }
      .servicesImageSection {
        @include width-height(290px, 480px);
      }
    }
  }
  // Here apply 800px Media Queries

  @media screen and (max-width: 600px) {
    @include text-margin(120px, 0px, 0px, 0px);

    .servicesShortHead {
      h2 {
        @include text-margin(0px, 0px, 10px, 0px);
        @include text-style(#000, 26px, 0.5px, 1.7em, 600, 0.5px, sans-serif);
      }
      span {
        @include text-style(#000000b3, 14px, 0.8px, 1.3em, 500, 0.5px, Arial);
      }
    }
    .servicesContainer {
      @include text-padding(0px, 0px, 0px, 0px);
      @include parent-flex(flex, flex-end, center, wrap, row);

      .serviceContainer-text {
        width: 80%;
        @include text-margin(0px, auto, 50px, auto);
      }
      .servicesImageSection {
        @include width-height(330px, 540px);
      }
    }
  }
  // Here apply 600px Media Queries

  @media screen and (max-width: 425px) {
    @include text-margin(100px, 0px, 0px, 0px);

    .servicesShortHead {
      h2 {
        @include text-margin(0px, 0px, 10px, 0px);
        @include text-style(#000, 24px, 0.5px, 1.7em, 600, 0.5px, sans-serif);
      }
      span {
        @include text-style(#000000b3, 12px, 0.8px, 1.3em, 500, 0.5px, Arial);
      }
    }
    .servicesContainer {
      .serviceContainer-text {
        width: 100%;
        @include text-padding(0px, 15px, 0px, 15px);
        .servicesQuote {
          width: 90%;
          @include text-margin(50px, auto, 20px, auto);
          img {
            @include width-height(48px, 48px);
          }
        }
      }
      .servicesImageSection {
        @include width-height(300px, 490px);
      }
    }
  }
  // Here apply 425px Media Queries

  @media screen and (max-width: 375px) {
    .servicesShortHead {
      width: 100%;
      h2 {
        @include text-margin(0px, 0px, 5px, 0px);
        @include text-style(#000, 22px, 0.5px, 1.7em, 600, 0.5px, sans-serif);
      }
    }
    .servicesContainer {
      @include text-padding(0px, 0px, 0px, 10px);
      @include text-margin(30px, auto, 5px, auto);

      .serviceContainer-text {
        .servicesQuote {
          width: 100%;
          @include text-margin(25px, auto, 25px, auto);
          .servicsInfo {
            width: 100%;
            h3 {
              @include text-style(#000, 14px, 0.2px, 1.2em, 600, 0px, Arial);
            }
            p {
              @include text-style(
                #00000066,
                12px,
                0.2px,
                1.2em,
                400,
                0px,
                Arial
              );
            }
          }
        }
      }
      .servicesImageSection {
        @include width-height(250px, 400px);
      }
    }
  }
  // Here apply 375px Media Queries
}
