:root {
  --max-width: 1200px;
  --horizontal-spacing: 40px;
  --background: #f3f3f3;
}

.shop-now {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background);
  overflow-x: hidden;
}

.container {
  max-width: var(--max-width);
  width: 100%;
}

.nav-wrapper {
  max-height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0px var(--horizontal-spacing);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05),
    0px 4px 4px 0px rgba(0, 0, 0, 0.04), 1px 9px 6px 0px rgba(0, 0, 0, 0.03),
    1px 16px 7px 0px rgba(0, 0, 0, 0.01), 2px 25px 7px 0px rgba(0, 0, 0, 0);
  background-color: white;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.nav-tagon-icon {
  width: 120px;
}

.nav-user-icon {
  width: 30px;
}

.p-10 {
  padding: 10px;
}

.p-5 {
  padding: 5px;
}

.section {
  background-color: white;
  padding: calc(var(--horizontal-spacing) / 2);
  border-radius: 20px;
}

.cards {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 20px;
}

.section-wrapper {
  padding: 0px calc(var(--horizontal-spacing) / 2);
}

.section-title {
  font-weight: bold;
  font-size: 25px;
}

.footer {
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--horizontal-spacing);
  position: relative;
}

.footer-logo {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.ql-title {
  font-size: 15px;
  font-weight: bold;
  white-space: nowrap;
}

.ql-links {
  display: flex;
  gap: 10px;
  flex-direction: column;
  font-size: 13px;
}

.product-details {
  display: flex;
  gap: 30px;
}

.images-section {
  display: flex;
  gap: 10px;
  margin: 0px auto;
}

.images {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.product-small-image {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  border: 1px solid #c8c8c8;
  cursor: pointer;
}

.product-small-image.active {
  border: 1px solid #1d4ed8;
}

.product-image {
  width: 300px;
  height: 300px;
  border-radius: 10px;
  border: 1px solid #c8c8c8;
}

.pd {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pd-title {
  font-size: 18px;
  font-weight: 600;
}

.rating-box {
  font-size: 12px;
  padding: 2px 7px;
  border-radius: 3px;
  background-color: #30b800;
  color: white;
}

.pd-description {
  font-size: 14px;
  font-weight: 300;
  text-align: justify;
}

.pd-price {
  display: flex;
  flex-direction: column;
  gap: 3px;
  font-size: 13px;
  font-weight: 400;
  color: #6a6a6a;
}

.pd-color-section {
  font-size: 13px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-weight: 600;
}

.pd-colors {
  display: flex;
  gap: 10px;
}

.pd-color {
  height: 30px;
  width: 30px;
  border-radius: 7px;
  border: 1px solid #c8c8c8;
}

.pd-sold-by {
  font-size: 15px;
  font-weight: 500;
}

.pd-parameter-section {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.pd-parameter {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.pd-param-label {
  font-size: 12px;
  font-weight: 400;
}

.pd-param-value {
  font-size: 12px;
  font-weight: 300;
  text-align: justify;
}

.pd-params-grid {
  display: grid;
  grid-template-columns: 100px auto;
  column-gap: 20px;
}

@media screen and (max-width: 800px) {
  :root {
    --horizontal-spacing: 20px;
  }
  .footer-container {
    padding-top: 200px;
  }
  .cards {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .product-details {
    flex-direction: column;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 650px) {
  .cards {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 450px) {
  .product-small-image {
    height: 50px;
    width: 50px;
  }
  .product-image {
    height: 250px;
    width: 250px;
  }
  .cards {
    grid-template-columns: 1fr;
  }
}
