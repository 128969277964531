.carousel-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel {
  display: flex;
  overflow: hidden;
  width: 100%;
  padding: 10px;
}

.advertisement {
  width: 100%;
  flex-shrink: 0;
}

.advertisement-fake {
  width: 100%;
  flex-shrink: 0;
}

.c-next,
.c-prev {
  position: absolute;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  color: black;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border: 1px solid rgba(0, 0, 0, 0.4);
}

.c-next {
  right: 10px;
}

.c-prev {
  left: 10px;
}

.indicator {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  padding: 5px 10px;
  border-radius: 10px;
}

.dot {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: white;
  transition: all 0.5s ease;
}

.dot.active {
  width: 15px;
  border-radius: 5px;
}
