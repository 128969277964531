.home-Wrapper {
  @include text-padding(0px, 0px, 75px, 0px);

  nav {
    z-index: 99;
    @include text-padding(20px, 20px, 20px, 20px);
    @include parent-flex(flex, space-between, center, wrap, row);

    .logo {
      @include width-height(120px, 48px);
      @include text-margin(0px, 0px, 0px, 50px);

      img {
        cursor: pointer;
        @include width-height(100%, 100%);
      }
    } // Logo

    .menu-list {
      list-style: none;
      @include parent-flex(flex, space-between, center, wrap, row);

      li a {
        cursor: pointer;
        text-decoration: none;
        @include text-margin(0px, 30px, 0px, 0px);
        @include text-style(#000, 18px, 0.8px, 0px, 500, 0.5px, Arial);
      }
      li:last-child {
        @include text-margin(0px, 30px, 0px, 0px);
      }
    } // Menu-List
  } // Navbar

  .hero-section {
    @include text-margin(10px, auto, 0px, auto);
    @include parent-flex(flex, center, flex-start, nowrap, row);

    .home-textSection {
      width: 45%;
      @include text-margin(100px, 0px, 0px, 0px);

      h1 {
        @include text-style(#000, 48px, 0.3px, 1.4em, 500, 0px, Quando);
      }
      p {
        @include text-margin(10px, 0px, 10px, 0px);
        @include text-style(#000000b3, 20px, 0.3px, 1.2em, 500, 0.2px, Arial);
      }
      .playstore-download {
        @include homeSection-bg(
          url("../images/google-play-png-logo.png"),
          center,
          cover,
          no-repeat
        );
        @include width-height(200px, 90px);
      } // Playstore Image
    } // Home-textSection
    .home-imageSection {
      @include width-height(540px, 730px);

      img {
        @include width-height(100%, 100%);
      }
    } //Home-imageSection
  } // Hero-Section

  @media screen and (max-width: 1200px) {
    @include text-padding(0px, 0px, 50px, 0px);

    nav {
      @include text-padding(20px, 10px, 20px, 10px);

      .logo {
        @include width-height(115px, 45px);
        @include text-margin(0px, 0px, 0px, 30px);
      }

      .menu-list {
        li a {
          @include text-margin(0px, 30px, 0px, 0px);
        }
        li:last-child {
          @include text-margin(0px, 10px, 0px, 0px);
        }
      }
    }

    .hero-section {
      .home-textSection {
        width: 50%;
        @include text-margin(60px, 20px, 0px, 0px);
        @include text-padding(0px, 0px, 0px, 20px);

        h1 {
          @include text-style(#000, 42px, 0.5px, 1.4em, 500, 0px, Quando);
        }
        p {
          @include text-style(#000000b3, 22px, 0.1px, 1.2em, 500, 0.5px, Arial);
        }
        .playstore-download {
          @include width-height(180px, 80px);
        }
      }

      .home-imageSection {
        @include text-margin(20px, 0px, 0px, 0px);
        @include width-height(460px, 600px);
      }
    }
  }
  // Here apply 1200px Media Queries

  @media screen and (max-width: 1000px) {
    @include text-padding(0px, 0px, 30px, 0px);

    nav {
      @include text-padding(15px, 15px, 15px, 15px);

      .logo {
        @include width-height(112px, 45px);
        @include text-margin(0px, 0px, 0px, 20px);
      }

      .menu-list {
        li a {
          @include text-margin(0px, 20px, 0px, 0px);
          @include text-style(#000, 17px, 0.8px, 0px, 500, 0.5px, Arial);
        }
        li:last-child {
          @include text-margin(0px, 5px, 0px, 0px);
        }
      }
    }

    .hero-section {
      @include text-margin(0px, 0px, 0px, 0px);
      @include text-padding(0px, 20px, 0px, 20px);

      .home-textSection {
        width: 48%;
        @include text-margin(80px, 0px, 0px, 0px);

        h1 {
          @include text-style(#000, 34px, 0.8px, 1.4em, 500, 0px, Quando);
        }
        p {
          @include text-margin(15px, 0px, 15px, 0px);
          @include text-style(#000000b3, 19px, 0.2px, 1.2em, 500, 0.4px, Arial);
        }
        .playstore-download {
          @include width-height(165px, 65px);
        }
      }

      .home-imageSection {
        @include width-height(430px, 540px);
      }
    }
  }
  // Here apply 1000px Media Queries
  @media screen and (max-width: 800px) {
    @include text-padding(0px, 0px, 30px, 0px);

    nav {
      @include text-padding(12px, 12px, 12px, 12px);

      .logo {
        @include width-height(90px, 36px);
      }

      .menu-list {
        li a {
          @include text-margin(0px, 5px, 0px, 20px);
          @include text-style(#000000, 16px, 0.8px, 0px, 500, 0px, Arial);
        }
      }
    }

    .hero-section {
      @include text-margin(15px, 0px, 0px, 0px);
      @include text-padding(0px, 0px, 0px, 10px);

      .home-textSection {
        @include text-padding(0px, 0px, 0px, 10px);
        @include text-margin(70px, 0px, 0px, 0px);

        h1 {
          @include text-style(#000, 30px, 0.8px, 1.4em, 500, 0.8px, Quando);
        }
        p {
          @include text-margin(15px, 0px, 15px, 0px);
          @include text-style(#000000b3, 16px, 0.2px, 1em, 500, 0.3px, Arial);
        }

        .playstore-download {
          @include width-height(125px, 55px);
        }
      }

      .home-imageSection {
        @include width-height(350px, 440px);
      }
    }
  }
  // Here apply 800px Media Queries
  @media screen and (max-width: 600px) {
    nav {
      @include text-padding(15px, 15px, 15px, 5px);

      .logo {
        @include width-height(90px, 35px);
      }

      .menu-list {
        li a {
          @include text-margin(0px, 0px, 0px, 20px);
          @include text-style(#000, 14px, 0.8px, 0px, 500, 0.5px, Arial);
        }
      }
    }

    .hero-section {
      @include parent-flex(flex, center, center, wrap, row);

      .home-textSection {
        width: 100%;
        text-align: center;
        @include text-padding(0px, 30px, 0px, 30px);
        @include text-margin(20px, 0px, 20px, 0px);

        h1 {
          @include text-style(#000, 28px, 0.5px, 1.2em, 500, 0.8px, Quando);
        }
        p {
          @include text-margin(10px, 0px, 10px, 0px);
          @include text-style(#000000b3, 15px, 0.3px, 1.2em, 500, 0.5px, Arial);
        }

        .playstore-download {
          margin: auto;
          @include width-height(150px, 60px);
        }
      }
      .home-imageSection {
        @include width-height(340px, 440px);
      }
    }
  }
  // Here apply 600px Media Queries

  @media screen and (max-width: 425px) {
    nav {
      @include text-padding(15px, 10px, 15px, 0px);

      .logo {
        @include width-height(80px, 35px);
      }

      .menu-list {
        li a {
          @include text-margin(0px, 0px, 0px, 20px);
          @include text-style(#000, 12px, 0.8px, 0px, 500, 0.5px, Arial);
        }
      }
    }

    .hero-section {
      @include text-padding(0px, 0px, 0px, 0px);

      .home-textSection {
        @include text-margin(30px, auto, 15px, auto);
        @include text-padding(0px, 15px, 0px, 15px);

        h1 {
          @include text-style(#000, 24px, 0.5px, 1.4em, 500, 0.8px, Quando);
        }
        p {
          @include text-margin(15px, 0px, 15px, 0px);
          @include text-style(#000000b3, 14px, 0.3px, 1.3em, 500, 0.5px, Arial);
        }
      }

      .home-imageSection {
        @include width-height(340px, 440px);
      }
    }
  }
  // Here apply 425px Media Queries

  @media screen and (max-width: 375px) {
    nav {
      @include text-padding(10px, 5px, 10px, 5px);

      .logo {
        @include width-height(80px, 35px);
        @include text-margin(8px, 0px, 0px, 0px);
      }
    }
    .hero-section {
      .home-textSection {
        @include text-margin(20px, 0px, 6px, 0px);

        h1 {
          @include text-style(#000, 24px, 0.5px, 1.3em, 500, 0.8px, Quando);
        }
        p {
          @include text-margin(8px, 0px, 8px, 0px);
          @include text-style(#000000b3, 13px, 0.3px, 1.3em, 500, 0.5px, Arial);
        }
      }

      .home-imageSection {
        @include width-height(98%, 420px);
      }
    }
  }
}
// Here apply 375px Media Queries
