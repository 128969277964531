.aboutUs-wrapper {
  @include text-margin(100px, 0px, 0px, 0px);

  h2 {
    text-align: center;
    @include text-style(#000, 36px, 0.5px, 1.9em, 600, 0.5px, sans-serif);
  }
  .about-section {
    @include text-margin(30px, 0px, 0px, 0px);
    @include parent-flex(flex, center, center, nowrap, row);

    .about-imageSection {
      @include width-height(480px, 550px);
      transition: all 0.5s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }

      img {
        @include width-height(100%, 100%);
      }
    }
    .about-paradivision {
      width: 40%;

      p {
        @include text-margin(20px, 0px, 0px, 0px);
        @include text-padding(0px, 30px, 0px, 60px);
        @include text-style(#000000b3, 24px, 1px, 1.4em, 500, 1px, Arial);
      }
    }
  } // About-section

  @media screen and (max-width: 1200px) {
    h2 {
      @include text-style(#000, 34px, 0.5px, 1.7em, 600, 0.5px, sans-serif);
    }

    .about-section {
      .about-imageSection {
        @include width-height(440px, 500px);
      }

      .about-paradivision {
        width: 50%;

        p {
          @include text-style(#000000b3, 22px, 0.8px, 1.5em, 500, 0.5px, Arial);
        }
      }
    }
  }
  // Here apply 1200px Media Queries

  @media screen and (max-width: 1000px) {
    h2 {
      @include text-style(#000, 32px, 0.5px, 1.6em, 600, 0.5px, sans-serif);
    }
    .about-section {
      .about-imageSection {
        @include width-height(420px, 460px);
      }
      .about-paradivision {
        width: 45%;
        p {
          @include text-style(#000000b3, 20px, 0.8px, 1.3em, 500, 0px, Arial);
        }
      }
    }
  }
  // Here apply 1000px Media Queries

  @media screen and (max-width: 800px) {
    h2 {
      @include text-style(#000, 30px, 0.5px, 1.9em, 600, 0.5px, sans-serif);
    }
    .about-section {
      .about-imageSection {
        @include width-height(390px, 430px);
      }
      .about-paradivision {
        p {
          @include text-style(#000000b3, 18px, 0.8px, 1.4em, 500, 0.2px, Arial);
        }
      }
    }
  }
  // Here apply 800px Media Queries

  @media screen and (max-width: 600px) {
    h2 {
      @include text-style(#000, 28px, 0.5px, 1.9em, 600, 0.5px, sans-serif);
    }

    .about-section {
      @include text-margin(30px, 0px, 0px, 0px);
      @include parent-flex(flex, center, center, wrap, column);

      .about-imageSection {
        @include width-height(380px, 400px);
      }
      .about-paradivision {
        width: 100%;
        p {
          @include text-padding(0px, 35px, 0px, 35px);
          @include text-margin(20px, 0px, 0px, 0px);
          @include text-style(#000000b3, 16px, 1px, 1.7em, 500, 0.7px, Arial);
        }
      }
    }
  }
  // Here apply 600px Media Queries

  @media screen and (max-width: 425px) {
    @include text-margin(80px, 0px, 0px, 0px);

    h2 {
      @include text-style(#000, 26px, 0.5px, 1.9em, 600, 0.5px, sans-serif);
    }

    .about-section {
      @include text-margin(20px, 0px, 0px, 0px);

      .about-imageSection {
        @include width-height(90%, 380px);
      }
      .about-paradivision {
        p {
          @include text-margin(15px, 0px, 0px, 0px);
          @include text-style(#000000b3, 14px, 0.8px, 1.5em, 500, 0.5px, Arial);
        }
      }
    }
  }
  // Here apply 425px Media Queries

  @media screen and (max-width: 375px) {
    @include text-margin(60px, 0px, 0px, 0px);

    h2 {
      @include text-style(#000, 24px, 0.5px, 1.2em, 600, 0.5px, sans-serif);
    }

    .about-section {
      @include text-padding(0px, 10px, 0px, 10px);

      .about-imageSection {
        @include width-height(100%, 350px);
      }
      .about-paradivision {
        p {
          @include text-margin(10px, 0px, 0px, 0px);
          @include text-style(#000000b3, 14px, 0.8px, 1.5em, 500, 0.5px, Arial);
        }
      }
    }
  }
  // Here apply 375px Media Queries
} // AboutUs-wrapper
